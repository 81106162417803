import { gql } from '@apollo/client';

/**
 * GraphQL categories and products query.
 */
const PRODUCTS_QUERY = gql`
	query ProductsQuery {
		t3: product(idType: SKU, id: "100-0007") {
				... on SimpleProduct {
				id
				name
				databaseId
				price
				sku
				image {
					sourceUrl(size: THUMBNAIL)
					srcSet(size: THUMBNAIL)
				}
			}
		}
		t3_accessories: product(idType: SKU, id: "smart-accessories") {
			... on SimpleProduct {
				id
				name
				databaseId
				price
				sku
				image {
					sourceUrl(size: THUMBNAIL)
					srcSet(size: THUMBNAIL)
				}
			}
		}
		warranties:products( last: 2, where: { category: "warranty", orderby: { field: PRICE, order: ASC } } ) {
			products:nodes {
				... on SimpleProduct {
				id
				databaseId
				name
				price
				sku
				}
			}
		}
	}
`;

export default PRODUCTS_QUERY;
